import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import * as Scroll from 'react-scroll'
import { Container, Row, Col, useScreenClass } from 'react-grid-system'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ContactForm from '../components/forms/contactForm'

const ScrollLink = Scroll.Link
const ScrollElement = Scroll.Element
const Events = Scroll.Events
const scroller = Scroll.scroller
const scrollSpy = Scroll.scrollSpy

const Wrapper = styled.div`
  color: ${props => props.theme.colors.white};
  padding-bottom: 37.7px;
  padding-top: 49px;
  position: relative;
  width: 100%;
`

const CtaTitle = styled.h2`
  font-size: 2.4rem;
  line-height: 1;
  margin-bottom: .5em;
  text-align: center;
`

const CtaText = styled.p`
  font-size: 1.2rem;
  line-height: 0.9166666666666667;
  text-align: center;
`

const StyledButton = styled.button`
  margin: auto;
  margin-top: 2em;
`

const FormPlaceholder = styled.div`
  height: 0;
  margin-top: 0;
  overflow: hidden;
  position: relative;
  transition: ${props => props.theme.styles.transitionAll};
  &.active {
    margin-top: 37.7px;
    height: 100%;
  }
`

const CtaBanner = (props) => {
  const {
    ctaTitle,
    ctaBody,
    ctaButton,
  } = props
  const [showForm, setShowForm] = useState(false)
  const screenClass = useScreenClass()

  const handleClick = () => {
    setShowForm(!showForm)
    if (!showForm) {
      scroller.scrollTo("embeddedForm", {
        smooth: true,
        offset: -190,
        duration: 400,
      })
    }
  }

  return (
    <Wrapper className={`bg-highlight`}>
      <Container style={{
        paddingLeft: ['md', 'lg', 'xl'].includes(screenClass) ? '3rem' : '1.5625rem',
        paddingRight: ['md', 'lg', 'xl'].includes(screenClass) ? '3rem' : '1.5625rem'
      }}>
        <Row align="center" justify="center">
          <Col lg={7}>
            <CtaTitle>
              {ctaTitle}
            </CtaTitle>
            <CtaText>
              {ctaBody}
            </CtaText>
          </Col>
        </Row>
        <Row align="center" justify="center">
          <Col xs="content">
            <StyledButton className={`button button-white`} onClick={(e) => handleClick()}>
              {ctaButton}
            </StyledButton>
          </Col>
        </Row>
        <Row justify="center">
          <Col md={8}>
            <FormPlaceholder className={showForm ? `active` : ``}>
              <ContactForm />
            </FormPlaceholder>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

export default CtaBanner
